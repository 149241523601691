export enum QuestionType {
  ADDITION = 'ADDITION',
  SUBTRACTION = 'SUBTRACTION',
  MULTIPLICATION = 'MULTIPLICATION',
  DIVISION = 'DIVISION',
}

export enum questionTypeToken {
  'ADDITION' = '+',
  'SUBTRACTION' = '-',
  'MULTIPLICATION' = 'x',
  'DIVISION' = '/',
}

export enum questionTypeIcon {
  'ADDITION' = 'plus',
  'SUBTRACTION' = 'minus',
  'MULTIPLICATION' = 'squares-2x2',
  'DIVISION' = 'chart-pie',
}

export const QuestionTypeOperand = {
  ADDITION: (a: number, b: number) => a + b,
  SUBTRACTION: (a: number, b: number) => a - b,
  MULTIPLICATION: (a: number, b: number) => a * b,
  DIVISION: (a: number, b: number) => a / b,
}

export enum QuestionLevels {
  BRONZE = 20,
  SILVER = 40,
  GOLD = 60,
  DIAMOND = 80,
  MASTER = 100,
}

export enum QuestionLevelColors {
  BRONZE = 'yellow-700',
  SILVER = 'gray-500',
  GOLD = 'yellow-500',
  DIAMOND = 'green-500',
  MASTER = 'orange-500',
}

export const VALID_EMAILS = [
  'mantritsin@gmail.com',
  'manoli@wlearn.academy',
  'keomaongafat@gmail.com',
  'keomakoekkoek@gmail.com',
  'keoma@wlearn.academy',
]

export enum Operands {
  FIRST = 'A',
  SECOND = 'B',
  THIRD = 'C',
  OPERATOR = 'D',
}

export enum operandVisualTypes {
  NUMBER = 'number',
  BLOCKS = 'blocks',
}

export const expressionRangePerChapter = [
  [1, 5], // Chapter 1
  [0, 9], // Chapter 2
  [0, 9], // Chapter 3
  [0, 9], // Chapter 4
  [1, 9], // Chapter 5
  [1, 20], // Chapter 6
  [1, 9], // Chapter 7 -> becomes multiples of ten
  [1, 9], // Chapter 8 -> becomes multiples of ten
  [10, 99], // Chapter 9
  [20, 99], // Chapter 10
]

// starting moments in the youtube instruction video per chapter
export const YTMathInstructionVid = [
  252, // Addition to 5
  478, // Addition to 9
  682, // Subtraction
  682, // Subtraction
  682, // Subtraction
  682, // Subtraction
  682, // Subtraction
  682, // Subtraction
  682, // Subtraction
  682, // Subtraction
  682, // Subtraction
]

export const NUMBER_OF_LEVELS = 5
export const NUMBER_OF_RELIGION_LEVELS = 5

export const HINTS_PER_LEVEL = [
  // Chapter 1
  [
    'Count the number of blocks. Then click on the blue button with the right number.',
    'Add both numbers together. Just as you did with the blocks, click on the blue button that total number.',
    'Now you have to add blocks or numbers. Just as before click on the blue button that has the total number.',
    'Select the two blocks that belong to each other. On the left you see the question and on the right the result. Match the blocks that belong together.',
    'This is the final level! If you find the questions difficult, you can go a level back.',
  ],
]

export enum graphDataType {
  PROGRESS = 'progress',
  TIME = 'time',
  MISTAKES = 'mistakes',
  RIGHTWRONG = 'rightWrong',
  DOMAINGROWTH = 'domainGrowth',
  COMPETENCEGROWTH = 'competenceGrowth',
}

export enum roles {
  ADMIN = 'admin',
  TEACHER = 'teacher',
  STUDENT = 'student',
  SYSTEM_ADMIN = 'system_admin',
}

export enum classes {
  MATH_B1 = 'math_b1',
  RELIGION_B1 = 'religion_b1',
}
